import React from 'react'

const SvgDataCoApp3 = props => (
  <svg id='dataCo_app3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 160 114' xmlSpace='preserve' {...props}>
    <style>
      {
        '.dataCo_app3_svg__st0{fill:#fd5c1f}.dataCo_app3_svg__st1,.dataCo_app3_svg__st5{fill:none;stroke-linecap:round;stroke-miterlimit:10}.dataCo_app3_svg__st1{stroke:#fd5c1f;stroke-width:1.5}.dataCo_app3_svg__st5{stroke:#fff}.dataCo_app3_svg__st6{fill:#fff}'
      }
    </style>
    <path
      id='dataCo_app3_svg__Combined-Shape_46_'
      className='dataCo_app3_svg__st0'
      d='M88.47 58.03c4.48 0 8.11-3.63 8.11-8.11s-3.63-8.11-8.11-8.11c-.11 0-.22 0-.33.01a8.109 8.109 0 00-15.88 2.31v.1c-.38-.06-.76-.1-1.16-.1-3.84 0-6.95 3.11-6.95 6.95a6.948 6.948 0 008.11 6.85v.1h16.21z'
    />
    <path
      className='dataCo_app3_svg__st1'
      d='M58.7 19.33a35.973 35.973 0 0120.57-6.42c11.48 0 21.72 5.35 28.35 13.7M63.77 81.81c-12.22-5.8-20.68-18.27-20.68-32.71 0-3.76.57-7.39 1.64-10.8M115.45 49.1c0 17.64-12.62 32.34-29.33 35.54'
    />
    <linearGradient
      id='dataCo_app3_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={16.55}
      y1={114.478}
      x2={143.45}
      y2={-12.422}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M153 101.03H7c-3.31 0-6-2.69-6-6v-88c0-3.31 2.69-6 6-6h146c3.31 0 6 2.69 6 6v88c0 3.31-2.69 6-6 6z'
      fill='none'
      stroke='url(#dataCo_app3_svg__SVGID_1_)'
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <linearGradient
      id='dataCo_app3_svg__SVGID_2_'
      gradientUnits='userSpaceOnUse'
      x1={55}
      y1={113.028}
      x2={105}
      y2={113.028}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      fill='none'
      stroke='url(#dataCo_app3_svg__SVGID_2_)'
      strokeWidth={2}
      strokeMiterlimit={10}
      d='M55 113.03h50'
    />
    <linearGradient
      id='dataCo_app3_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={73.212}
      y1={115.171}
      x2={86.843}
      y2={101.54}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#252a3a' stopOpacity={0.8} />
    </linearGradient>
    <path fill='url(#dataCo_app3_svg__SVGID_3_)' d='M89.99 112.03H70.07l2.65-10h14.61z' />
    <path className='dataCo_app3_svg__st5' d='M74.5 46.53h15v4h-15z' />
    <path className='dataCo_app3_svg__st6' d='M80.15 47.95h7v1h-7z' />
    <circle className='dataCo_app3_svg__st6' cx={77.29} cy={48.45} r={0.5} />
    <path className='dataCo_app3_svg__st5' d='M74.5 50.53h15v4h-15z' />
    <path className='dataCo_app3_svg__st6' d='M80 52.03h7v1h-7z' />
    <circle className='dataCo_app3_svg__st6' cx={77.5} cy={52.53} r={0.5} />
    <g>
      <path className='dataCo_app3_svg__st0' d='M81 87.03H67v-5h14v5zm-13-1h12v-3H68v3z' />
      <path className='dataCo_app3_svg__st0' d='M73.15 83.95h5v1h-5z' />
      <circle className='dataCo_app3_svg__st0' cx={70.29} cy={84.45} r={0.5} />
      <path className='dataCo_app3_svg__st0' d='M81 83.03H67v-5h14v5zm-13-1h12v-3H68v3z' />
      <path className='dataCo_app3_svg__st0' d='M73.15 79.95h5v1h-5z' />
      <circle className='dataCo_app3_svg__st0' cx={70.29} cy={80.45} r={0.5} />
      <path className='dataCo_app3_svg__st0' d='M81 91.03H67v-5h14v5zm-13-1h12v-3H68v3z' />
      <path className='dataCo_app3_svg__st0' d='M73 88.03h5v1h-5z' />
      <circle className='dataCo_app3_svg__st0' cx={70.5} cy={88.53} r={0.5} />
    </g>
    <g>
      <path className='dataCo_app3_svg__st0' d='M122 41.03h-14v-5h14v5zm-13-1h12v-3h-12v3z' />
      <path className='dataCo_app3_svg__st0' d='M114.15 37.95h5v1h-5z' />
      <circle className='dataCo_app3_svg__st0' cx={111.29} cy={38.45} r={0.5} />
      <path className='dataCo_app3_svg__st0' d='M122 37.03h-14v-5h14v5zm-13-1h12v-3h-12v3z' />
      <path className='dataCo_app3_svg__st0' d='M114.15 33.95h5v1h-5z' />
      <circle className='dataCo_app3_svg__st0' cx={111.29} cy={34.45} r={0.5} />
      <path className='dataCo_app3_svg__st0' d='M122 45.03h-14v-5h14v5zm-13-1h12v-3h-12v3z' />
      <path className='dataCo_app3_svg__st0' d='M114 42.03h5v1h-5z' />
      <circle className='dataCo_app3_svg__st0' cx={111.5} cy={42.53} r={0.5} />
    </g>
    <g>
      <path className='dataCo_app3_svg__st0' d='M56 30.03H42v-5h14v5zm-13-1h12v-3H43v3z' />
      <path className='dataCo_app3_svg__st0' d='M48.15 26.95h5v1h-5z' />
      <circle className='dataCo_app3_svg__st0' cx={45.29} cy={27.45} r={0.5} />
      <path className='dataCo_app3_svg__st0' d='M56 26.03H42v-5h14v5zm-13-1h12v-3H43v3z' />
      <path className='dataCo_app3_svg__st0' d='M48.15 22.95h5v1h-5z' />
      <circle className='dataCo_app3_svg__st0' cx={45.29} cy={23.45} r={0.5} />
      <path className='dataCo_app3_svg__st0' d='M56 34.03H42v-5h14v5zm-13-1h12v-3H43v3z' />
      <path className='dataCo_app3_svg__st0' d='M48 31.03h5v1h-5z' />
      <circle className='dataCo_app3_svg__st0' cx={45.5} cy={31.53} r={0.5} />
    </g>
  </svg>
)

export default SvgDataCoApp3

import React from 'react'

const SvgDataCoArchEn = props => (
  <svg id='dataCo_arch_en_svg__tuceng_1' x={0} y={0} viewBox='0 0 950.04 324.15' xmlSpace='preserve' {...props}>
    <style>
      {
        '.dataCo_arch_en_svg__st1{font-family:&apos;OpenSans&apos;}.dataCo_arch_en_svg__st3{fill:#8796aa}.dataCo_arch_en_svg__st4{font-size:12px}.dataCo_arch_en_svg__st5{fill:#00b5b0}.dataCo_arch_en_svg__st6{fill:#fd5c1f}.dataCo_arch_en_svg__st7{fill:none}'
      }
    </style>
    <path fill='none' stroke='#8796aa' strokeMiterlimit={10} strokeDasharray='4,4' d='M217.81 62.7h360v160h-360z' />
    <text transform='translate(329.81 99.672)' className='dataCo_arch_en_svg__st1' fontSize={18}>
      {'Local data center'}
    </text>
    <text
      transform='translate(252.81 193.672)'
      className='dataCo_arch_en_svg__st3 dataCo_arch_en_svg__st1 dataCo_arch_en_svg__st4'
    >
      {'Raysync Server'}
    </text>
    <text
      transform='translate(3.287 185.672)'
      className='dataCo_arch_en_svg__st3 dataCo_arch_en_svg__st1 dataCo_arch_en_svg__st4'
    >
      {'Web Browser'}
    </text>
    <text
      transform='translate(471.585 193.672)'
      className='dataCo_arch_en_svg__st3 dataCo_arch_en_svg__st1 dataCo_arch_en_svg__st4'
    >
      {'Local Storage'}
    </text>
    <path
      className='dataCo_arch_en_svg__st5'
      d='M514.3 140.39v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.68c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.01-5.79-.41-8.03-1.14zM511.17 129.15c.03 1.74 4.79 3.68 11.15 3.68 6.38 0 11.16-1.95 11.16-3.69s-4.77-3.69-11.16-3.69c-6.38 0-11.14 1.94-11.15 3.69v.01zM487.48 154.54v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.02-12.67-2.94z'
    />
    <path
      className='dataCo_arch_en_svg__st5'
      d='M487.48 146.81v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.01-12.67-2.94zM514.3 147.6v5.64c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.69c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.02-5.79-.41-8.03-1.14z'
    />
    <path
      className='dataCo_arch_en_svg__st5'
      d='M487.48 139.09v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.02-12.67-2.94zM522.33 134.31c-4.35 0-8.88-.92-11.15-2.65V133c1.91.89 3.13 2.07 3.13 3.53v2.28c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.69c-2.27 1.74-6.81 2.66-11.16 2.66zM514.3 154.81v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.68c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.01-5.79-.41-8.03-1.14zM487.48 162.26v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.02-12.67-2.94z'
    />
    <path
      className='dataCo_arch_en_svg__st5'
      d='M500.15 132.52c-7.25 0-12.67 2.12-12.67 4.01s5.42 4.01 12.67 4.01 12.67-2.12 12.67-4.01-5.42-4.01-12.67-4.01z'
    />
    <text
      transform='translate(818.418 70.672)'
      className='dataCo_arch_en_svg__st3 dataCo_arch_en_svg__st1 dataCo_arch_en_svg__st4'
    >
      {'Remote Data Center'}
    </text>
    <path
      className='dataCo_arch_en_svg__st5'
      d='M876.72 17.39v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.68c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.01-5.79-.41-8.03-1.14zM873.59 6.15c.03 1.74 4.79 3.68 11.15 3.68 6.38 0 11.16-1.95 11.16-3.69s-4.77-3.69-11.16-3.69c-6.38 0-11.14 1.94-11.15 3.69v.01zM849.9 31.54v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.99-.01-10.19-1.02-12.67-2.94z'
    />
    <path
      className='dataCo_arch_en_svg__st5'
      d='M849.9 23.81v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.99 0-10.19-1.01-12.67-2.94zM876.72 24.6v5.64c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.69c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.02-5.79-.41-8.03-1.14z'
    />
    <path
      className='dataCo_arch_en_svg__st5'
      d='M849.9 16.09v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.99 0-10.19-1.02-12.67-2.94zM884.74 11.31c-4.35 0-8.88-.92-11.15-2.65V10c1.91.89 3.13 2.07 3.13 3.53v2.28c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69V8.65c-2.27 1.74-6.81 2.66-11.16 2.66zM876.72 31.81v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.68c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.01-5.79-.41-8.03-1.14zM849.9 39.26v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.99 0-10.19-1.02-12.67-2.94z'
    />
    <path
      className='dataCo_arch_en_svg__st5'
      d='M862.57 9.52c-7.25 0-12.67 2.12-12.67 4.01s5.42 4.01 12.67 4.01 12.67-2.12 12.67-4.01c-.01-1.89-5.42-4.01-12.67-4.01z'
    />
    <g>
      <text
        transform='translate(802.914 191.672)'
        className='dataCo_arch_en_svg__st3 dataCo_arch_en_svg__st1 dataCo_arch_en_svg__st4'
      >
        {'Multinational Data Center'}
      </text>
    </g>
    <g>
      <path
        className='dataCo_arch_en_svg__st5'
        d='M876.72 138.39v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.68c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.01-5.79-.41-8.03-1.14zM873.59 127.15c.03 1.74 4.79 3.68 11.15 3.68 6.38 0 11.16-1.95 11.16-3.69s-4.77-3.69-11.16-3.69c-6.38 0-11.14 1.94-11.15 3.69v.01zM849.9 152.54v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.99-.01-10.19-1.02-12.67-2.94z'
      />
      <path
        className='dataCo_arch_en_svg__st5'
        d='M849.9 144.81v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.99 0-10.19-1.01-12.67-2.94zM876.72 145.6v5.64c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.69c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.02-5.79-.41-8.03-1.14z'
      />
      <path
        className='dataCo_arch_en_svg__st5'
        d='M849.9 137.09v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.99 0-10.19-1.02-12.67-2.94zM884.74 132.31c-4.35 0-8.88-.92-11.15-2.65V131c1.91.89 3.13 2.07 3.13 3.53v2.28c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.69c-2.27 1.74-6.81 2.66-11.16 2.66zM876.72 152.81v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.68c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.01-5.79-.41-8.03-1.14zM849.9 160.26v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.99 0-10.19-1.02-12.67-2.94z'
      />
      <path
        className='dataCo_arch_en_svg__st5'
        d='M862.57 130.52c-7.25 0-12.67 2.12-12.67 4.01s5.42 4.01 12.67 4.01 12.67-2.12 12.67-4.01c-.01-1.89-5.42-4.01-12.67-4.01z'
      />
    </g>
    <g>
      <path
        className='dataCo_arch_en_svg__st6'
        d='M303.69 128.44h-30.74c-2.5 0-4.51 1.97-4.51 4.33s2.01 4.33 4.51 4.33h30.74c2.46 0 4.51-1.93 4.51-4.33s-2.06-4.33-4.51-4.33zm-18.04 5.33h-12v-2.67h12v2.67zm6.23.34c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48 0 .78-.69 1.48-1.54 1.48zm5.48 0c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48.05.78-.64 1.48-1.54 1.48zm5.31 0c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48 1.54.66 1.54 1.48c.05.78-.68 1.48-1.54 1.48zM303.69 138.44h-30.74c-2.46 0-4.51 1.93-4.51 4.33 0 2.36 2.01 4.33 4.51 4.33h30.74c2.46 0 4.51-1.93 4.51-4.33 0-2.4-2.06-4.33-4.51-4.33zm-18.04 6h-12v-2.67h12v2.67zm6.31.3c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48.05.82-.68 1.48-1.54 1.48zm5.53 0c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48s-.68 1.48-1.54 1.48zm5.31 0c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48s-.68 1.48-1.54 1.48zM303.85 157.1c2.46 0 4.51-1.93 4.51-4.33 0-2.36-2.01-4.33-4.51-4.33h-30.74c-2.46 0-4.51 1.93-4.51 4.33 0 2.36 2.01 4.33 4.51 4.33h14.53v4h-2v2.67h-16v2.67h16v2h6.67v-2h14.67v-2.67h-14.67v-2.67h-2v-4h13.54zm-.96-6.15c.86 0 1.54.66 1.54 1.48s-.69 1.48-1.54 1.48c-.86 0-1.54-.66-1.54-1.48s.68-1.48 1.54-1.48zm-5.32 0c.86 0 1.54.66 1.54 1.48s-.69 1.48-1.54 1.48c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48zm-5.48 0c.86 0 1.54.66 1.54 1.48s-.69 1.48-1.54 1.48c-.86 0-1.54-.66-1.54-1.48-.05-.83.64-1.48 1.54-1.48zm-6.44 2.82h-12v-2.67h12v2.67z'
      />
    </g>
    <g>
      <path className='dataCo_arch_en_svg__st7' d='M464.23 150.78h-133' />
      <path className='dataCo_arch_en_svg__st3' d='M331.23 149.78h133v2h-133z' />
      <path className='dataCo_arch_en_svg__st3' d='M463.81 144.78l8 6-8 6' />
      <g>
        <path className='dataCo_arch_en_svg__st3' d='M331.81 156.78l-8-6 8-6' />
      </g>
    </g>
    <g>
      <path className='dataCo_arch_en_svg__st7' d='M207.26 150.78h-129' />
      <path className='dataCo_arch_en_svg__st3' d='M78.26 149.78h129v2h-129z' />
      <g>
        <path className='dataCo_arch_en_svg__st3' d='M78.84 156.78l-8-6 8-6' />
      </g>
    </g>
    <g>
      <path className='dataCo_arch_en_svg__st7' d='M696.26 28.7h118' />
      <path className='dataCo_arch_en_svg__st3' d='M696.26 27.7h118v2h-118z' />
      <g>
        <path className='dataCo_arch_en_svg__st3' d='M813.68 34.7l8-6-8-6' />
      </g>
    </g>
    <g>
      <path className='dataCo_arch_en_svg__st7' d='M696.26 150.78h118' />
      <path className='dataCo_arch_en_svg__st3' d='M696.26 149.78h118v2h-118z' />
      <g>
        <path className='dataCo_arch_en_svg__st3' d='M813.68 156.78l8-6-8-6' />
      </g>
    </g>
    <g>
      <path className='dataCo_arch_en_svg__st3' d='M577.26 149.7h118v2h-118z' />
    </g>
    <g>
      <path className='dataCo_arch_en_svg__st7' d='M694.26 273.7h118' />
      <path className='dataCo_arch_en_svg__st3' d='M694.26 272.7h118v2h-118z' />
      <g>
        <path className='dataCo_arch_en_svg__st3' d='M811.68 279.7l8-6-8-6' />
      </g>
    </g>
    <g>
      <path className='dataCo_arch_en_svg__st7' d='M696.26 28.7v246' />
      <path className='dataCo_arch_en_svg__st3' d='M694.26 28.7h2v246h-2z' />
    </g>
    <g>
      <path
        id='dataCo_arch_en_svg__Combined-Shape_39_'
        className='dataCo_arch_en_svg__st5'
        d='M896.4 293.7h-3v-33h-41v30h44v3h-47v-36h47z'
      />
      <path id='dataCo_arch_en_svg__Rectangle-17_4_' className='dataCo_arch_en_svg__st5' d='M863.4 297.7h19v3h-19z' />
      <path id='dataCo_arch_en_svg__Rectangle-73_4_' className='dataCo_arch_en_svg__st5' d='M871.4 291.7h3v8h-3z' />
      <path
        id='dataCo_arch_en_svg__Rectangle-Copy-2_2_'
        className='dataCo_arch_en_svg__st5'
        d='M858.4 267.7h29v3h-29z'
      />
      <path
        id='dataCo_arch_en_svg__Rectangle-Copy-3_2_'
        className='dataCo_arch_en_svg__st5'
        d='M858.4 273.7h29v3h-29z'
      />
      <path
        id='dataCo_arch_en_svg__Rectangle-Copy-4_2_'
        className='dataCo_arch_en_svg__st5'
        d='M858.4 279.7h29v3h-29z'
      />
    </g>
    <g>
      <path
        className='dataCo_arch_en_svg__st6'
        d='M55.63 137.27c.85-1.92 1.67-4.72 1.67-6.86 0-5.68-6.22-7.69-16.38-3.02-8.41-.56-16.5 5.76-18.52 14.57 2.41-2.72 6.11-6.43 9.53-7.9-4.24 3.77-7.86 8.55-10.6 13.53-1.62 2.96-3.44 7.75-3.44 11.33 0 7.7 7.26 6.76 14.08 3.28 2.45 1.2 5.2 1.22 7.94 1.22 7.59 0 14.4-4.24 16.94-11.41h-9.44c-4.1 6.92-15.38 4.14-15.38-3.71h25.69c.5-3.41-.13-7.49-2.09-11.03zm-32.7 14.62c1.39 3.99 4.2 7.49 7.83 9.64-6.93 3.81-13.54 2.26-7.83-9.64zm9.06-8.51c.16-4.31 3.93-7.41 8.13-7.41 4.17 0 7.97 3.1 8.13 7.41H31.99zm14.42-14.66c1.67-.8 3.79-1.72 5.67-1.72 2.45 0 4.24 1.7 4.24 4.2 0 1.56-.58 3.83-1.14 5.3-2.05-3.3-5.16-6.37-8.77-7.78z'
      />
    </g>
    <text
      transform='translate(804.924 319.815)'
      className='dataCo_arch_en_svg__st3 dataCo_arch_en_svg__st1 dataCo_arch_en_svg__st4'
    >
      {'Tripartite Cloud Platform'}
    </text>
  </svg>
)

export default SvgDataCoArchEn

import React from 'react'

const SvgDataCoFeature1 = props => (
  <svg id='dataCo_feature1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.dataCo_feature1_svg__st0{fill:#fd5c1f}'}</style>
    <path
      className='dataCo_feature1_svg__st0'
      d='M14.5 26.04c1.14 0 1.42.67.61 1.48-.81.81-1.49 1.5-1.52 1.52-.02.03-.02.07 0 .09l1.45 1.45c.77.78 1.42 1.43 1.45 1.46.02.02.07.02.09 0 .02-.03.71-.71 1.52-1.53.81-.81 1.47-.54 1.47.61v2.16c0 .04.03.06.06.06h4.09c.04 0 .06-.03.06-.06v-2.16c0-1.15.66-1.43 1.47-.61.81.81 1.49 1.5 1.52 1.53.02.03.07.03.09 0l1.45-1.45c.77-.77 1.42-1.43 1.45-1.45.02-.03.02-.07 0-.09-.02-.03-.71-.71-1.52-1.52s-.53-1.48.61-1.48h2.14c.04 0 .06-.03.06-.06v-2.06-2.06c0-.04-.03-.06-.06-.06h-2.14c-1.14 0-1.42-.67-.61-1.48.81-.81 1.49-1.5 1.52-1.52.02-.03.02-.07 0-.09-.02-.03-.68-.68-1.45-1.46-.77-.77-1.42-1.43-1.45-1.45-.02-.02-.07-.02-.09 0-.02.03-.71.71-1.52 1.53-.81.81-1.47.54-1.47-.61v-2.16c0-.04-.03-.06-.06-.06h-4.09c-.04 0-.06.03-.06.06v2.16c0 1.15-.66 1.43-1.47.61-.81-.81-1.49-1.5-1.52-1.53-.03-.02-.07-.02-.09 0-.02.03-.68.68-1.45 1.45-.77.78-1.42 1.43-1.45 1.46-.02.03-.02.07 0 .09.03.02.71.71 1.52 1.52s.53 1.48-.61 1.48h-2.14c-.04 0-.06.03-.06.06v4.12c0 .04.03.06.06.06.03-.01.99-.01 2.14-.01zm7.31-6.24c2.22 0 4.03 1.81 4.03 4.05 0 2.24-1.8 4.05-4.03 4.05-2.22 0-4.03-1.81-4.03-4.05 0-2.24 1.81-4.05 4.03-4.05zM31.75 15.13c.55 0 .68.32.29.71-.39.39-.71.71-.72.73a.03.03 0 000 .04l.69.69.69.69c.01.01.03.01.04 0 .01-.01.34-.34.72-.73.39-.39.7-.26.7.29v1.03c0 .02.01.03.03.03h1.96c.02 0 .03-.01.03-.03v-1.03c0-.55.32-.68.7-.29.39.39.71.72.72.73.01.01.03.01.04 0l.69-.69.69-.69a.03.03 0 000-.04c-.01-.01-.34-.34-.72-.73-.39-.39-.25-.71.29-.71h1.02c.02 0 .03-.01.03-.03v-.98-.98c0-.02-.01-.03-.03-.03h-1.02c-.55 0-.68-.32-.29-.71.39-.39.71-.72.72-.73a.03.03 0 000-.04l-.69-.69-.69-.69a.03.03 0 00-.04 0c-.01.01-.34.34-.72.73-.39.39-.7.26-.7-.29V9.66c0-.02-.01-.03-.03-.03h-1.96c-.02 0-.03.01-.03.03v1.03c0 .55-.32.68-.7.29-.39-.39-.71-.72-.72-.73a.03.03 0 00-.04 0l-.69.69-.69.69a.03.03 0 000 .04c.01.01.34.34.72.73.39.39.25.71-.29.71h-1.02c-.02 0-.03.01-.03.03v1.96c0 .02.01.03.03.03h1.02zm3.49-2.98c1.06 0 1.92.86 1.92 1.93 0 1.07-.86 1.93-1.92 1.93s-1.92-.86-1.92-1.93c0-1.06.85-1.93 1.92-1.93z'
    />
    <path
      d='M46.27 4H3.73C2.22 4 1 5.26 1 6.8v29.4C1 37.74 2.22 39 3.73 39H25v5h-8v2h18v-2h-8v-5H46.26c1.51 0 2.73-1.26 2.73-2.8V6.8C49 5.26 47.78 4 46.27 4zM47 37H3V6h44v31z'
      fill='#2b4b67'
    />
  </svg>
)

export default SvgDataCoFeature1
